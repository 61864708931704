<template>
  <div class="flex flex-row w-full lg:w-page-width mt-24 lg:mt-20 mb-3 lg:mb-6">
    <router-view class="mt-3">
    </router-view>
  </div>
</template>

<script>
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AdminView",
  components: {
    PageContainer,
    PageTitle
  },
  computed: {
    ...mapGetters('dictionaries', ['categoriesData']),
    ...mapGetters('admin', ['adminDashboardData'])
  },
  methods: {
    ...mapActions('dictionaries', ['fetchCategoriesData', 'fetchUserFiltersData']),
    ...mapActions('admin', ['fetchAdminDashboardData']),
  },
  mounted() {
    this.fetchUserFiltersData();

    if(!this.categoriesData) {
      this.fetchCategoriesData();
    }
  }
}
</script>
